import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getOptimizelyClient } from 'app/lib/analytics';

import { APP_URL, SALES_AUTH_URL, OPTIMIZELY_ENABLED } from 'config';
import { isBoolean, merge, pickBy } from 'lodash';
import get from 'lodash/get';
import * as SentryLib from '@sentry/browser';
import getApplication from 'redux/services/getApplication';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import browserHistory from 'components/root/browserHistory';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { EVENTS } from 'app/lib/analytics/constants';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';
import {
  trackEcommEvent,
  trackEvent,
  setOptlyReady,
} from 'app/redux/modules/Analytics/actions';
import { createProduct } from 'app/lib/analytics';

import * as PersistenceEngine from 'app/redux/modules/Persistence/engine.local';
import { PartnerLoginApi } from '../PartnerLogin/api';
import { RestoreApi } from 'redux/modules/Restore/api';
import * as restoreActions from 'redux/modules/Restore/actions';
import { PUT_BROADBAND_SUCCESS } from 'redux/modules/Broadband/constants';
import { translateBackendErrorToMessage } from '../Shared/BackendParser';
import * as actions from './actions';
import { ApplicationApi } from './api';
import postApplicationComplete from '../../services/postApplicationComplete';
import getLineCheckStatusRequest from './service/getLineCheckStatus';
import getBankHolidays from './service/getBankHolidays';
import {
  createOrganicApplication,
  createPartnerApplication,
  createPartnerApplicationRemoteSupport,
  createRemoteApplication,
  createSalesApplication,
  createSalesApplicationIAM,
  createSalesApplicationTTJWT,
} from './service/postCreateApplication';
import { updateRemoteSupportingPartner } from './service/updateApplication';
import getRegistrationToken from './service/getRegistrationToken';
import { postFundingPaymentCardSuccess } from '../PaymentForm/actions';
import SalesLoginApi from 'redux/modules/SalesLogin/api';

import { yourDetailsSubmissionEnded } from 'app/redux/modules/OrderSummary/actions';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import {
  APP_GET_APPLICATION_REQUEST,
  APP_GET_APPLICATION_SUCCESS,
  APP_POST_APPLICATION_COMPLETE_REQUEST,
  APP_POST_APPLICATION_CREATED_ORGANIC_REQUEST,
  APP_POST_APPLICATION_CREATED_PARTNER_REQUEST,
  APP_POST_APPLICATION_CREATED_REMOTE_REQUEST,
  APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_REQUEST,
  APP_POST_APPLICATION_CREATED_SALES_REQUEST,
  APP_POST_APPLICATION_CREATED_SALES_IAM_REQUEST,
  APP_POST_APPLICATION_CREATED_SALES_TT_JWT_REQUEST,
  APP_POST_APPLICATION_CREATED_SUCCESS,
  APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST,
  APP_REGISTRATION_TOKEN_FETCH,
  APP_SALES_ID_SET,
  APP_SET_BRAND_PARTNER,
  APP_START_AGAIN,
  APP_USER_LOGOUT,
  LINE_CHECK_STATUS_BROADBAND,
  RENDER_APP,
  APP_GET_BANK_HOLIDAYS,
} from './constants';
import { renderApp } from './render';

const returnToSummaryErrorMessages = [
  'Application deposits have not been calculated',
  'Invalid deposit calculations',
  'Application requires a risk check but one has not been performed',
  'Debit card is required for this payment',
  'Card holder name must match that of the main account holder:',
  'Payment declined',
];

export function* combinedSagas() {
  yield takeLatest(APP_GET_APPLICATION_REQUEST, handleGetApplication);
  yield takeLatest(
    APP_POST_APPLICATION_COMPLETE_REQUEST,
    handlePostApplicationComplete
  );
  yield takeLatest(
    PUT_BROADBAND_SUCCESS,
    handlePutHomePhoneBroadband(LINE_CHECK_STATUS_BROADBAND)
  );
  yield takeLatest(
    APP_POST_APPLICATION_CREATED_PARTNER_REQUEST,
    handleCreatePartnerApplication
  );
  yield takeLatest(
    APP_POST_APPLICATION_CREATED_ORGANIC_REQUEST,
    handleCreateOrganicApplication
  );
  yield takeLatest(
    APP_POST_APPLICATION_CREATED_SALES_REQUEST,
    handleCreateSalesApplication
  );
  yield takeLatest(
    APP_POST_APPLICATION_CREATED_SALES_IAM_REQUEST,
    handleCreateSalesApplicationIAM
  );
  yield takeLatest(
    APP_POST_APPLICATION_CREATED_SALES_TT_JWT_REQUEST,
    handleCreateSalesApplicationTTJWT
  );
  yield takeLatest(
    APP_POST_APPLICATION_CREATED_REMOTE_REQUEST,
    handleCreateRemoteApplication
  );
  yield takeLatest(APP_START_AGAIN, handleStartAgain);
  yield takeLatest(APP_USER_LOGOUT, handleLogout);
  yield takeLatest(RENDER_APP, handleRenderApp);
  yield takeLatest(
    APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST,
    handlePutRemoteApplicationSupporting
  );

  yield takeLatest(
    APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_REQUEST,
    handleCreatePartnerApplicationRemoteSupport
  );

  yield takeLatest(APP_REGISTRATION_TOKEN_FETCH, fetchRegistrationToken);

  yield takeLatest(APP_GET_BANK_HOLIDAYS, handleGetBankHolidays);

  yield takeLatest(
    [
      APP_GET_APPLICATION_SUCCESS,
      APP_POST_APPLICATION_CREATED_SUCCESS,
      APP_SALES_ID_SET,
      APP_SET_BRAND_PARTNER,
      APP_USER_LOGOUT,
      RENDER_APP,
    ],
    handleApplicationTracing
  );
}

function* handleApplicationTracing() {
  const id = yield select((state) => ApplicationApi.getId(state));
  const isSales = yield select((state) =>
    ApplicationApi.isSalesApplication(state)
  );
  const isTalkTalk = yield select((state) =>
    ApplicationApi.isTalkTalkApplication(state)
  );
  const isOrganic = yield select((state) =>
    ApplicationApi.isOrganicApplication(state)
  );
  const isRemote = yield select((state) =>
    ApplicationApi.isRemoteSession(state)
  );
  const accountId = yield select((state) => ApplicationApi.getAccountId(state));
  const accountNumber = yield select((state) =>
    ApplicationApi.getAccountNumber(state)
  );
  const salesId = yield select((state) => ApplicationApi.getSalesId(state));
  const partnerId = yield select((state) =>
    PartnerLoginApi.getPartnerId(state)
  );
  const supportingPartnerId = yield select((state) =>
    PartnerLoginApi.getSupportingId(state)
  );
  const hostedPartnerId = yield select((state) =>
    PartnerLoginApi.getHostedId(state)
  );
  const brandPartnerId = yield select((state) =>
    ApplicationApi.getBrandPartnerId(state)
  );
  const brandPartnerAgentId = yield select((state) =>
    ApplicationApi.getBrandPartnerAgentId(state)
  );
  const remotePartnerId = yield select((state) =>
    ApplicationApi.getRemotePartnerId(state)
  );
  const remoteSupportingPartnerId = yield select((state) =>
    ApplicationApi.getRemoteSupportingPartnerId(state)
  );

  SentryLib.setTag('application.id', id || undefined);
  SentryLib.setTag('application.isSales', isSales ?? undefined);
  SentryLib.setTag('application.isTalkTalk', isTalkTalk ?? undefined);
  SentryLib.setTag('application.isOrganic', isOrganic ?? undefined);
  SentryLib.setTag('application.isRemote', isRemote ?? undefined);

  // account
  SentryLib.setTag('application.account.id', accountId || undefined);
  SentryLib.setTag('application.account.number', accountNumber || undefined);

  // sales
  SentryLib.setTag('application.sales.id', salesId || undefined);

  // partner
  SentryLib.setTag(
    'application.partner.id',
    partnerId || remotePartnerId || undefined
  );
  SentryLib.setTag(
    'application.supportingPartner.id',
    supportingPartnerId || remoteSupportingPartnerId || undefined
  );
  SentryLib.setTag(
    'application.hostedPartner.id',
    hostedPartnerId || undefined
  );
  SentryLib.setTag('application.brandPartner.id', brandPartnerId || undefined);
  SentryLib.setTag(
    'application.brandPartner.agent.id',
    brandPartnerAgentId || undefined
  );
}

function* handleOptlyReady() {
  const optlyClient = getOptimizelyClient();
  const optlyClientReady = optlyClient?.isClientReady;
  yield put(setOptlyReady(optlyClientReady));

  if (!optlyClientReady) {
    yield call([optlyClient, optlyClient.onReady]);
    yield put(setOptlyReady(true));
  }
}

function* handleRenderApp() {
  const { location } = browserHistory;
  const params = new URLSearchParams(location.search);
  const paramsObj = {};

  for (const [key, value] of params.entries()) {
    paramsObj[key] = value;
  }

  yield put(actions.setPageParams(paramsObj));
  renderApp();
  if (OPTIMIZELY_ENABLED) {
    yield fork(handleOptlyReady);
  }
}

function* handleStartAgain() {
  // do last selections before store is wiped
  const salesId = yield select(ApplicationApi.getSalesId);

  yield put(actions.logoutUserApplication());
  if (salesId) {
    browserHistory.push('/loading');
    window.location.href = SALES_AUTH_URL;
    return;
  }

  browserHistory.push('/');
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
}

function* handleLogout() {
  yield call(PersistenceEngine.clear);
  browserHistory.push('/');
}

function* handleCreateApplicationSuccess(application) {
  if (application.lead) {
    yield put(actions.setLead(application.lead));
  }
  if (application.brandPartnerId) {
    yield put(
      actions.setBrandPartner(
        application.brandPartnerId,
        application.brandPartnerAgentId
      )
    );
  }
}

function* handleCreatePartnerApplication(action) {
  if (yield select(RestoreApi.shouldApplyRestoredApplication)) {
    yield put(restoreActions.applyRestoredApplication());
    return;
  }

  try {
    const response = yield call(
      createPartnerApplication,
      action.customerRelationship,
      pickBy(action.referrer, (f) => (f && isBoolean(f)) || f.length > 0),
      action.supportingPartnerId,
      action.supportingPartnerToken,
      action.hostedId,
      action.lead
    );
    const token = response.headers.token;
    const applicationId = response.data.uuid;
    yield put(actions.getApplicationSuccess(token, applicationId));
    yield fork(handleCreateApplicationSuccess, response.data);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationFailure(message));
    if (message !== 'Partner ID not supported') {
      Sentry.log(error, ERRORS.UPDATE_PARTNER_APP);
      const errorMessage = `We encountered an error creating your application. ${message}`;
      yield put(openErrorGlobalDialog(errorMessage));
    }
  }
}

function* handleCreateRemoteApplication(action) {
  try {
    const response = yield call(
      createRemoteApplication,
      action.remotePartnerId,
      action.customerRelationship,
      pickBy(action.referrer, (f) => (f && isBoolean(f)) || f.length > 0),
      action.lead
    );
    const token = response.headers.token;
    const { uuid: applicationId, appointmentCode } = response.data;

    yield put(
      actions.createRemoteApplicationSuccess(
        token,
        applicationId,
        appointmentCode,
        action.remotePartnerId
      )
    );
    yield fork(handleCreateApplicationSuccess, response.data);
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationFailure(message));
    if (message !== 'Partner ID not supported') {
      Sentry.log(error, ERRORS.UPDATE_PARTNER_REMOTE_APP);
      const errorMessage = `We encountered an error creating your application. ${message}`;
      yield put(openErrorGlobalDialog(errorMessage));
    }
  }
}

export function* handleCreateOrganicApplication(action) {
  try {
    const response = yield call(
      createOrganicApplication,
      action.hostedId,
      action.leadCode
    );
    const token = response.headers.token;
    const applicationId = response.data.uuid;
    const hostedPartnerProfile = get(
      response,
      'data.partnerRelationship.hostedPartner',
      {}
    );
    yield put(
      actions.getApplicationSuccess(token, applicationId, hostedPartnerProfile)
    );
    yield fork(handleCreateApplicationSuccess, response.data);
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_ORGANIC_APP);
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationFailure(message));
    const errorMessage = `We encountered an error creating your application. ${message}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

function* handleCreateSalesApplication(action) {
  try {
    const lead = yield select((state) => SalesLoginApi.getLead(state));
    const leadId = get(lead, 'id');
    const response = yield call(createSalesApplication, action.token, leadId);
    const token = response.headers.token;
    const applicationId = response.data.uuid;
    yield put(actions.getApplicationSuccess(token, applicationId));
    yield put(actions.setSalesId(response.data.salesId));
    yield fork(handleCreateApplicationSuccess, response.data);
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_SALES_APP);
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationFailure(message));
    const errorMessage = `We encountered an error creating your application. ${message}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

function* handleCreateSalesApplicationIAM(action) {
  try {
    const lead = yield select((state) => SalesLoginApi.getLead(state));
    const leadId = get(lead, 'id');
    const response = yield call(
      createSalesApplicationIAM,
      action.token,
      leadId
    );
    const token = response.headers.token;
    const applicationId = response.data.uuid;
    yield put(actions.getApplicationSuccess(token, applicationId));
    yield put(actions.setSalesId(response.data.salesId));
    yield fork(handleCreateApplicationSuccess, response.data);
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_SALES_APP);
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationFailure(message));
    const errorMessage = `We encountered an error creating your application. ${message}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

function* handleCreateSalesApplicationTTJWT(action) {
  try {
    const response = yield call(
      createSalesApplicationTTJWT,
      action.id_token.replace(/^\s+|\s+$/g, '')
    );
    const token = response.headers.token;
    const applicationId = response.data.uuid;
    yield put(actions.getApplicationSuccess(token, applicationId));
    yield fork(handleCreateApplicationSuccess, response.data);
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_SALES_APP);
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationFailure(message));
    const errorMessage = `We encountered an error creating your application. ${message}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

function handlePutHomePhoneBroadband(successType) {
  return function* getLineCheckStatus() {
    yield put(actions.setLineCheckBroadbandRequesting());
    try {
      const {
        data: { status },
      } = yield call(getLineCheckStatusRequest);
      yield put(actions.getLineCheckStatusSuccess(status, successType));
    } catch (error) {
      Sentry.log(error, ERRORS.GET_LINE_CHECK_STATUS);
      const message = translateBackendErrorToMessage(error);
      const errorMessage = `We encountered an error while getting the line check status. ${message}`;
      yield put(openErrorGlobalDialog(errorMessage));
    }
  };
}

export function* handleGetApplication() {
  yield put(actions.getApplicationLoading());
  try {
    const response = yield call(getApplication);
    const applicationId = response.data.uuid;
    const accountNumber = response.data.accountNumber;
    yield put(actions.getApplicationDataSuccess(applicationId, accountNumber));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_APP_DETAILS);
    const message = translateBackendErrorToMessage(error);
    yield put(actions.getApplicationDataFailure(error));
    const errorMessage = `We encountered an error while retrieving the application details. ${message}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

function* handleApplicationSubmitConversionTracking() {
  const { productTotals } = yield select(OrderSummaryApi.getTotals);

  const formatProductName = (id) => id[0].toUpperCase() + id.slice(1);

  if (productTotals === null || productTotals === undefined) {
    return;
  }

  try {
    const products = Object.entries(productTotals).map(([productId, cost]) => {
      const productPrice = parseInt(cost) / Math.pow(10, 2);
      return createProduct(
        productPrice,
        `Join the Club - ${formatProductName(productId)}`,
        productId
      );
    });

    if (products) {
      const appId = yield select(ApplicationApi.getCurrentApplication);
      const params = {
        gtm: true,
        transactionId: appId,
      };
      yield put(
        trackEcommEvent(EVENTS.APPLICATION_SUBMITTED, products, params)
      );
    }
  } catch (err) {
    Sentry.log(err, ERRORS.APP_SUBMIT_ANALYTICS);
  }
}

export function* handlePostApplicationComplete() {
  yield put(actions.postApplicationCompleteLoading());

  // check if the order required a payment
  // if not skip it to get the account number and go to the thank you page
  try {
    const requires3dsPayment = yield select(ApplicationApi.requires3dsPayment);
    const buildReturnUri = requires3dsPayment
      ? `${APP_URL}/checkout/verificationEnd`
      : undefined;
    const response = yield call(postApplicationComplete, buildReturnUri);
    const verificationUrl = get(response, 'data.verificationUrl');
    // need check if there is a verificationUrl from the response
    // and dispatch an action if needed
    if (verificationUrl) {
      // send action to go to verification form
      return yield put(postFundingPaymentCardSuccess({ verificationUrl }));
    } else {
      // no verification needed, send analytics and go to thank you page
      const appSubmittedState = yield select(
        analyticsSelectors[EVENTS.APPLICATION_SUBMITTED]
      );
      yield put(trackEvent(EVENTS.APPLICATION_SUBMITTED, appSubmittedState));
      yield fork(handleApplicationSubmitConversionTracking);
      return yield put(actions.postApplicationCompleteSuccess(response.data));
    }
  } catch (error) {
    Sentry.log(error, ERRORS.GET_APP_COMPLETE);
    let message = translateBackendErrorToMessage(error);
    const returnToSummary = returnToSummaryErrorMessages.find((err) =>
      message.startsWith(err)
    );

    if (get(error, 'response.data.type') === 'PaymentApiError') {
      const validationDetails = get(
        error,
        'response.data.validationDetails',
        ''
      );
      const errorCodes = get(error, 'error.response.data.errorCodes', []);
      message = `${validationDetails}: ${errorCodes.join(', ')}`;
    }

    yield put(
      actions.postApplicationCompleteFailure(error, message, !!returnToSummary)
    );
    return yield put(openErrorGlobalDialog(message));
  } finally {
    // Consolidation Form
    yield put(yourDetailsSubmissionEnded());
  }
}

export function* handlePutRemoteApplicationSupporting({ supportingPartnerId }) {
  try {
    const [applicationId, remotePartnerId, token] = yield select((state) => [
      ApplicationApi.getCurrentApplication(state),
      ApplicationApi.getRemotePartnerId(state),
      ApplicationApi.getToken(state),
    ]);

    yield call(
      updateRemoteSupportingPartner,
      applicationId,
      remotePartnerId,
      supportingPartnerId,
      token
    );

    yield put(
      actions.putRemoteApplicationSupportingSuccess(supportingPartnerId)
    );
  } catch (error) {
    Sentry.log(
      error,
      merge(ERRORS.UPDATE_REMOTE_SUPPORTING, {
        extra: { supportingPartnerId },
      })
    );
    const message = translateBackendErrorToMessage(error);
    const errorMessage = `We encountered an error while updating application details. ${message}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* handleCreatePartnerApplicationRemoteSupport({
  customerRelationship,
  referrer,
  hostedId,
  lead,
}) {
  const partnerId = yield select(PartnerLoginApi.getPartnerId);
  try {
    const response = yield call(
      createPartnerApplicationRemoteSupport,
      partnerId,
      customerRelationship,
      pickBy(referrer, (f) => (f && isBoolean(f)) || f.length > 0),
      hostedId,
      lead
    );
    const token = response.headers.token;
    const { uuid: applicationId, appointmentCode } = response.data;
    yield put(
      actions.createApplicationPartnerRemoteSupportSuccess(
        token,
        applicationId,
        appointmentCode,
        partnerId
      )
    );
  } catch (error) {
    const message = translateBackendErrorToMessage(error);
    yield put(actions.createApplicationPartnerRemoteSupportFailure(message));
    if (message !== 'Partner ID not supported') {
      Sentry.log(error, ERRORS.UPDATE_PARTNER_APP);
      const errorMessage = `We encountered an error creating your application. ${message}`;
      yield put(openErrorGlobalDialog(errorMessage));
    }
  }
}

export function* fetchRegistrationToken() {
  try {
    const response = yield call(getRegistrationToken);
    yield put(actions.fetchRegistrationTokenSuccess(response.data.token));
  } catch (err) {
    const message = translateBackendErrorToMessage(err);
    yield put(actions.fetchRegistrationTokenFailure(message));
    yield put(openErrorGlobalDialog(message));
  }
}

export function* handleGetBankHolidays() {
  try {
    const { data } = yield call(getBankHolidays);
    const holidays = data ? data['england-and-wales'].events : null;
    yield put(actions.getBankHolidaysSuccess(holidays));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_BANK_HOLIDAYS);
    yield put(actions.getBankHolidaysFailure(error));
  }
}
