import * as types from './constants';

export const applicationGetStartedPartner = (
  partnerId,
  partnerToken,
  customerRelationship,
  referrer,
  supportingPartnerId,
  supportingPartnerToken,
  hostedId,
  lead
) => ({
  type: types.APP_POST_APPLICATION_CREATED_PARTNER_REQUEST,
  partnerId,
  partnerToken,
  customerRelationship,
  referrer,
  supportingPartnerId,
  supportingPartnerToken,
  hostedId,
  lead,
});

export const createApplicationPartnerRemoteSupport = (
  partnerId,
  partnerToken,
  customerRelationship,
  referrer,
  hostedId,
  lead
) => ({
  type: types.APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_REQUEST,
  partnerId,
  partnerToken,
  customerRelationship,
  referrer,
  hostedId,
  lead,
});

export const createApplicationPartnerRemoteSupportSuccess = (
  token,
  applicationId,
  appointmentCode,
  partnerId
) => ({
  type: types.APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_SUCCESS,
  token,
  applicationId,
  appointmentCode,
  partnerId,
});

export const createApplicationPartnerRemoteSupportFailure = (error) => ({
  type: types.APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_FAILURE,
  error,
});

export const applicationGetStartedRemote = (
  remotePartnerId,
  customerRelationship,
  referrer,
  supportingPartnerId,
  lead
) => ({
  type: types.APP_POST_APPLICATION_CREATED_REMOTE_REQUEST,
  remotePartnerId,
  customerRelationship,
  referrer,
  supportingPartnerId,
  lead,
});

export const applicationGetStarted = (hostedId, leadCode) => ({
  type: types.APP_POST_APPLICATION_CREATED_ORGANIC_REQUEST,
  hostedId,
  leadCode,
});

export const applicationGetStartedSales = ({ token }) => ({
  type: types.APP_POST_APPLICATION_CREATED_SALES_REQUEST,
  token,
});

export const applicationGetStartedSalesIAM = ({ token }) => ({
  type: types.APP_POST_APPLICATION_CREATED_SALES_IAM_REQUEST,
  token,
});

export const applicationGetStartedSalesTTJWT = ({ id_token }) => ({
  type: types.APP_POST_APPLICATION_CREATED_SALES_TT_JWT_REQUEST,
  id_token,
});

export const getApplicationSuccess = (
  token,
  applicationId,
  hostedPartnerProfile
) => ({
  type: types.APP_POST_APPLICATION_CREATED_SUCCESS,
  token,
  applicationId,
  hostedPartnerProfile,
});

export const createRemoteApplicationSuccess = (
  token,
  applicationId,
  appointmentCode,
  partnerId
) => ({
  type: types.APP_CREATE_REMOTE_APPLICATION_CREATED_SUCCESS,
  token,
  applicationId,
  appointmentCode,
  partnerId,
});

export const getApplicationDataSuccess = (applicationId, accountNumber) => ({
  type: types.APP_GET_APPLICATION_SUCCESS,
  applicationId,
  accountNumber,
});
export const getApplicationDataFailure = (error) => ({
  type: types.APP_GET_APPLICATION_FAILURE,
  error,
});
export const getApplicationFailure = (error) => ({
  type: types.APP_POST_APPLICATION_CREATED_FAILURE,
  error,
});

export const getApplicationRequest = () => ({
  type: types.APP_GET_APPLICATION_REQUEST,
});
export const getApplicationLoading = () => ({
  type: types.APP_GET_APPLICATION_LOADING,
});
export const postApplicationCompleteRequest = () => ({
  type: types.APP_POST_APPLICATION_COMPLETE_REQUEST,
});
export const postApplicationCompleteLoading = () => ({
  type: types.APP_POST_APPLICATION_COMPLETE_LOADING,
});

export const postApplicationCompleteSuccess = (response) => {
  return {
    type: types.APP_POST_APPLICATION_COMPLETE_SUCCESS,
    accountNumber: response.value,
    smartLink: response.smartLink,
    accountId: response.customerAccountId,
  };
};

export const resetApplicationComplete = () => ({
  type: types.APP_RESET_COMPLETE_REQUEST,
});

export const postApplicationCompleteFailure = (
  error,
  message,
  returnToSummary
) => ({
  type: types.APP_POST_APPLICATION_COMPLETE_FAILURE,
  error,
  message,
  returnToSummary,
});

export const logoutUserApplication = () => ({ type: types.APP_USER_LOGOUT });
export const getLineCheckStatusSuccess = (status, type) => ({ type, status });

export const setLineCheckBroadbandRequesting = () => ({
  type: types.LINE_CHECK_STATUS_BROADBAND_REQUEST,
});

export const setBrowserDialog = (show) => ({
  type: types.BROWSER_DIALOG_SET,
  show,
});

export const editOrder = (service, newService = false) => ({
  type: types.EDIT_ORDER,
  service,
  newService,
});

export const newEditMobile = () => ({
  type: types.NEW_MOBILE_EDIT_PRODUCT,
});

export const editOrderDone = () => ({
  type: types.EDIT_ORDER_DONE,
});

export const renderApp = () => ({ type: types.RENDER_APP });

export const putRemoteApplicationSupportingRequest = (supportingPartnerId) => ({
  type: types.APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST,
  supportingPartnerId,
});

export const putRemoteApplicationSupportingSuccess = (supportingPartnerId) => ({
  type: types.APP_PUT_REMOTE_APPLICATION_SUPPORTING_SUCCESS,
  supportingPartnerId,
});

export const setStartAgainDialog = (open) => ({
  type: types.APP_START_AGAIN_SET,
  open,
});

export const startAgain = () => ({
  type: types.APP_START_AGAIN,
});

export const setSalesId = (salesId) => ({
  type: types.APP_SALES_ID_SET,
  salesId,
});

export const fetchRegistrationToken = () => ({
  type: types.APP_REGISTRATION_TOKEN_FETCH,
});

export const fetchRegistrationTokenSuccess = (token) => ({
  type: types.APP_REGISTRATION_TOKEN_FETCH_SUCCESS,
  token,
});

export const fetchRegistrationTokenFailure = (token) => ({
  type: types.APP_REGISTRATION_TOKEN_FETCH_FAILURE,
  token,
});

export const setReadOnly = () => ({
  type: types.APP_SET_APPLICATION_READONLY,
});

export const setPageParams = (params) => ({
  type: types.APP_SET_PAGE_PARAMS,
  params,
});

export const setExclusionParams = (params) => ({
  type: types.APP_SET_EXCLUSION_PARAMS,
  params,
});

export const setLead = (lead) => ({
  type: types.APP_SET_LEAD,
  lead,
});

export const setBrandPartner = (brandPartnerId, brandPartnerAgentId) => ({
  type: types.APP_SET_BRAND_PARTNER,
  brandPartnerId,
  brandPartnerAgentId,
});

export const getBankHolidays = () => ({
  type: types.APP_GET_BANK_HOLIDAYS,
});

export const getBankHolidaysSuccess = (bankHolidays) => ({
  type: types.APP_GET_BANK_HOLIDAYS_SUCCESS,
  bankHolidays,
});

export const getBankHolidaysFailure = (error) => ({
  type: types.APP_GET_BANK_HOLIDAYS_FAILURE,
  error,
});
